/** @jsxImportSource theme-ui */

import * as React from 'react';

import { Box, Container } from 'theme-ui';

import Layout from "../Layout";
import Header from "../Header";
import Footer from "../Footer";
import CodeStyles from "../styles/code" // TODO: Fix code highlighting

const PageLayout: React.FC = ({ children }) => {

  // TODO: Add SEO
  return (
    <Layout>
      <Container sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: `100vh`,
      }}>
        {/* SEO */}
        <Header />
        <Box as="main" variant="layout.main" sx={{ flex: 1, }}>
          {children}
        </Box>
        <div
          sx={{
            mt: 4,
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <Footer />
        </div>
      </Container>
    </Layout>
  )
}

export default PageLayout;
