import * as React from 'react';

import { Box, Flex, useColorMode, Button } from "theme-ui"

const Footer: React.FC<{ isHome?: boolean }> = ({ isHome= false, ...props}) => {

  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  }

  // TODO: Add Moon and Sun icon to button
  return (
    <Box
      as="footer"
      variant={isHome ? `footer` : ``}
      {...props}
    >
      <Button
        sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
      </Button>
      <br />
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
        }}
      >
        <Box as="p">
          Copyright © {new Date().getFullYear()}
          {` `} All rights reserved.
        </Box>
      </Flex>
    </Box>
  )
}

export default Footer;
