/** @jsxImportSource theme-ui */

import * as React from 'react';
import { Link } from "gatsby";

const Navigation = () => {

  const navItems = [
    {
      label: 'Blog',
      href: '/blog',
    },
    {
      label: 'About',
      href: '/about',
    },
  ];

  return (
    <React.Fragment>
      <nav sx={{ "a:not(:last-of-type)": { mr: 3 }, fontSize: [1, `18px`], ".active": { color: `heading` } }}>
        {navItems.map((navItem) =>
          <Link
            activeClassName="active"
            key={navItem.href}
            sx={(t) => ({ ...t.styles?.a })}
            to={navItem.href}
          >
            {navItem.label}
          </Link>
        )}
      </nav>
    </React.Fragment>
  );
}

export default Navigation;
