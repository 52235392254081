/** @jsxImportSource theme-ui */

import * as React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import { Flex } from 'theme-ui';

import Navigation from "../Nav";
import { hideOnMobile } from "../styles";

const Header: React.FC = () => {

  /* TODO: Replace Home Nav with baseSite URl */
  /* TODO: Add Social Media log, twitter, github, linkedIn */

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          logoText
          social {
            twitter
          }
        }
      }
    }
  `);

  // get links
  const logoText = data?.site?.siteMetadata?.logoText;
  const social = data?.site?.siteMetadata?.social;

  return (
    <header>
      <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>
        <Link
          aria-label={`${logoText} - Back to home`}
          to={'/'}
          sx={{ color: `heading`, textDecoration: `none` }}
        >
          <div sx={{ my: 0, fontWeight: `medium`,  fontSize: [3, 4]}}>{logoText}</div>
        </Link>
        <Navigation />
        <a
          href={`https://github.com/malikwahab`}
          sx={{
            display: hideOnMobile
          }}
          target="_blank"
        >
          Github
        </a>
      </Flex>
    </header>
  );
}

export default Header;
