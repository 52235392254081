/** @jsxImportSource theme-ui */

import * as React from 'react';
import PageLayout from "../components/PageLayout";
import Seo from '../components/seo';
import { Heading, Paragraph, Box } from "theme-ui";

const About: React.FC = () => {

  return (
    <PageLayout>
      <div
        sx={{
          pt: [2, 4],
          pb: [2, 4]
        }}
      >
        <Seo title="About - Malik Wahab"/>
        <Box sx={{ paddingBottom: 50 }}>
          <Heading as="h1">About Me</Heading>
          <Paragraph variant="styles.p" sx={{ paddingTop: 20 }}>
            I'm Abdulmalik, a programmer and writer from Nigeria living in Toronto, Canada.
            I currently work at <a href="https://www.lazertechnologies.com/">Lazer Technologies</a>, where I help build software products. I'm passionate about open source software development and creating content.
          </Paragraph>
          <Paragraph variant="styles.p" sx={{ paddingTop: 20 }}>
            You can catch me watching <span style={{ textDecoration: 'line-through' }}>soccer</span> football, hanging out with friends or watching astronomy documentaries when I'm not working.
          </Paragraph>
        </Box>

        <Heading as="h2" variant="styles.h2">Get in touch</Heading>
        <Paragraph variant="styles.p">
          <a href="mailto:contact@malikwahab.com">contact@malikwahab.com</a>
        </Paragraph>
      </div>
    </PageLayout>
  );
}

export default About;
